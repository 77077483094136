<template lang="pug">
div
  div.gigs
    h4
      |Ben Schreiber's performing schedule starting January 1st,&nbsp
      span(v-if="!showHistory") {{ currentYear }}
      span(v-else) {{ gigList[0].startyear }}
      |.
    br
    p.small As of {{ today }}.
    br
    p.small
      b-link(@click="toggleHistory()") Toggle History
    br
    div(v-if="showHistory")
      b-table(:items="gigTotals", :fields="totalFields", :busy="isBusy", :tbody-tr-class="rowClass", borderless)
        template(#foot(year)="data")
          span data
    br
    p.center-loader(v-if="isBusy")
      b-spinner.align-middle.mx-2
      strong Loading...
    div.tables(v-bind:class="ready")
      b-table.mt-3#gigs(:items="gigList", :fields="fields", :busy="isBusy", :tbody-tr-class="rowClass", borderless)
        template(#cell(startyear)="data")
          span.small-caps.year-label(v-if="data.item.startyear > data.item.prevyear || data.index==0") {{ data.item.startyear }}
        template(#cell(startdateenddate)="data")
          span.next-gig-wrapper.d-none.d-md-block
            span.next-gig.bounce-right(v-if="data.item.nextgig == true")
              |next&nbsp
              b-icon-forward-fill
          span.cancel-wrapper
            //- span.cancel-icon-wrapper-mobile.d-none(v-b-tooltip.click.blur="{ customClass: 'custom-tooltip' }", title="Canceled",  tabindex="0")
            //-   b-icon-exclamation-circle-fill.ml-1
            span(v-if="data.item.enddate")
              span(v-if="data.item.startyear == data.item.endyear")
                span(v-if="data.item.startmonth == data.item.endmonth") {{ data.item.startdate | dateParse('MM/DD/YYYY') | dateFormat('MMM D') }}-{{ data.item.enddate | dateParse('MM/DD/YYYY') | dateFormat('D, YYYY') }}
                span(v-else) {{ data.item.startdate | dateParse('MM/DD/YYYY') | dateFormat('MMM D') }} - {{ data.item.enddate | dateParse('MM/DD/YYYY') | dateFormat('MMM D, YYYY') }}
              span(v-else) {{ data.item.startdate | dateParse('MM/DD/YYYY') | dateFormat('MMM D, YYYY') }} - {{ data.item.enddate | dateParse('MM/DD/YYYY') | dateFormat('MMM D, YYYY') }}
            span(v-else) {{ data.item.startdate | dateParse('MM/DD/YYYY') | dateFormat('dddd, MMM D, YYYY') }}
            span.cancel-icon-wrapper-desktop.d-none(v-b-tooltip="{ customClass: 'custom-tooltip' }" title="Canceled")
              b-icon-exclamation-circle-fill.ml-1
        template(#cell(band)="data")
          span.cancel-wrapper
            span {{ data.item.band }}
        template(#cell(citystateprovince)="data")
          span.cancel-wrapper
            span
              b.text-info {{ data.item.city.toUpperCase() }}
              |,&nbsp
              b {{ data.item.stateprovince }}
        template(#cell(event)="data")
          span.cancel-wrapper
            span(v-bind:class="data.item.category")
              b-link(v-if="data.item.link", :href="data.item.link", target="_blank")
                span {{ data.item.event }}
                b-icon-box-arrow-up-right.ml-1.text-muted.smaller
              span(v-else) {{ data.item.event }}
      div(v-if="!isBusy")
        br
        br
        br
        //div {{bandList}}
</template>

<script>
export default {
  name: "Gigs",
  data() {
    return {
      fields: [
        { key: "startyear", label: "" },
        { key: "startdateenddate", label: "Date" },
        "band",
        { key: "citystateprovince", label: "Location" },
        "event"
      ],
      totalFields: [
        { key: "year", label: "Year" },
        { key: "numDances", label: "Dances" },
        { key: "numCamps", label: "Camps" },
        { key: "numConcerts", label: "Concerts" },
        { key: "numOther", label: "Other" },
        { key: "numGigs", label: "Total" }
      ],
      gigList: [],
      bandList: [],
      gigTotals: [],
      isBusy: true,
      ready: "notready",
      today: new Date().toLocaleString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      }),
      currentYear: new Date().getFullYear(),
      showHistory: false
    };
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      var classString = "";
      if (item.band && item.band != item.prevband) {
        classString = classString + " new-band";
      }
      if (item.band && item.band == item.prevband) {
        classString = classString + " same-band";
      }
      if (item.startyear > item.prevyear) {
        classString = classString + " new-year";
      }
      if (item.lastofyear === true) {
        classString = classString + " last-gig-of-year";
      }
      if (item.startyear < this.currentYear) {
        classString = classString + " d-none";
      }
      if (item.status === "canceled") {
        classString = classString + " canceled";
      }
      if (item.pastgig === true) {
        classString = classString + " past-gig";
      }
      if (item.year === "Total:") {
        classString = classString + " is-footer";
      }
      if (item.numGigs) {
        classString = classString + " gig-total";
      }
      return classString;
    },
    toggleHistory() {
      this.showHistory = !this.showHistory;
      if (this.showHistory === true) {
        this.currentYear = 1989;
      } else {
        this.currentYear = new Date().getFullYear();
      }
    },
    getGigs() {
      this.isBusy = true;
      this.ready = "notready";
      fetch(process.env.VUE_APP_GIGSHEET, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
        .then(response => response.json())
        .then(data => {
          var gigData = [];
          var bandData = [];
          var totalData = [];
          var currentTotal = {
            year: 0,
            numDances: 0,
            numConcerts: 0,
            numCamps: 0,
            numOther: 0,
            numGigs: 0
          };
          var overallTotal = {
            year: 0,
            numDances: 0,
            numConcerts: 0,
            numCamps: 0,
            numOther: 0,
            numGigs: 0
          };
          var bandSet = new Set();
          var startDate = [];
          var endDate = [];
          var curDate = new Date()
            .toLocaleString()
            .split(",")[0]
            .split("/");
          var curDay = Number(curDate[1]);
          var curMonth = Number(curDate[0]);
          var curYear = Number(curDate[2]);
          var prevBand = null;
          var prevYear = null;

          data.values.forEach(function(value, index) {
            startDate = value[2].split("/");
            endDate = value[3].length > 1 ? value[3].split("/") : startDate;

            var entry = {
              index: value[0],
              startdate: value[2],
              startmonth: startDate[0],
              startday: startDate[1],
              startyear: startDate[2],
              enddate: value[3],
              endmonth: endDate[0],
              endday: endDate[1],
              endyear: endDate[2],
              prevyear: prevYear || startDate[2],
              prevband: prevBand || value[4],
              band: value[4],
              city: value[5],
              stateprovince: value[6],
              event: value[7],
              category: value[8],
              status: value[9],
              link: value[10],
              lastofyear: false,
              nextgig: false,
              pastgig: false
            };

            // If this is the last gig of the year, set lastofyear to true
            if (prevYear && prevYear < startDate[2]) {
              gigData[index - 1]["lastofyear"] = true;
            }

            // If this is the last gig in the index, set lastofyear to true
            if (index + 1 == data.values.length) {
              entry["lastofyear"] = true;
            }

            // console.log(curDay + " " + curMonth + " " + curYear);
            // console.log(Number(entry["startday"]) + " " + Number(entry["startmonth"]) + " " + Number(entry["startyear"]));
            // if (gigData[index-1]) {
            //   console.log(Number(gigData[index-1]["startday"]) + " " + Number(gigData[index-1]["startmonth"]) + " " + Number(gigData[index-1]["startyear"]));
            // }

            if (
              gigData[index - 1] &&
              entry["startyear"] >= curYear &&
              entry["startmonth"] >= curMonth &&
              entry["startday"] >= curDay &&
              gigData[index - 1]["startyear"] <= curYear &&
              ((gigData[index - 1]["startmonth"] == curMonth &&
                gigData[index - 1]["startday"] < curDay) ||
                gigData[index - 1]["startmonth"] < curMonth)
            ) {
              entry["nextgig"] = true;
            }

            if (
              entry["endyear"] < curYear ||
              (entry["endyear"] == curYear && entry["endmonth"] < curMonth) ||
              (entry["endyear"] == curYear &&
                entry["endmonth"] == curMonth &&
                entry["endday"] < curDay)
            ) {
              entry["pastgig"] = true;
            }

            // Set prevyear to year of current entry
            prevYear = startDate[2];

            // Set prevband to band of current entry
            prevBand = value[4];

            // Add to the set of bands
            bandSet.add(entry.band);

            // Push entry into the list of gigs
            gigData.push(entry);

            if (index > 0) {
              if (
                gigData[index - 1] &&
                gigData[index - 1]["startyear"] < entry["startyear"]
              ) {
                currentTotal["year"] = gigData[index - 1]["startyear"];
                var totalEntry = {
                  year: currentTotal["year"],
                  numDances: currentTotal["numDances"],
                  numConcerts: currentTotal["numConcerts"],
                  numCamps: currentTotal["numCamps"],
                  numOther: currentTotal["numOther"],
                  numGigs: currentTotal["numGigs"]
                };
                totalData.push(totalEntry);
                currentTotal["year"] = entry["startyear"];
                currentTotal["numDances"] = 0;
                currentTotal["numConcerts"] = 0;
                currentTotal["numCamps"] = 0;
                currentTotal["numOther"] = 0;
                currentTotal["numGigs"] = 0;
              }
              if (entry["category"] == "dance") {
                currentTotal["numDances"] += 1;
                currentTotal["numGigs"] += 1;
                overallTotal["numDances"] += 1;
                overallTotal["numGigs"] += 1;
              } else if (entry["category"] == "camp") {
                currentTotal["numCamps"] += 1;
                currentTotal["numGigs"] += 1;
                overallTotal["numCamps"] += 1;
                overallTotal["numGigs"] += 1;
              } else if (entry["category"] == "concert") {
                currentTotal["numConcerts"] += 1;
                currentTotal["numGigs"] += 1;
                overallTotal["numConcerts"] += 1;
                overallTotal["numGigs"] += 1;
              } else {
                currentTotal["numOther"] += 1;
                currentTotal["numGigs"] += 1;
                overallTotal["numOther"] += 1;
                overallTotal["numGigs"] += 1;
              }
            }
          });

          var totalEntry = {
            year: currentTotal["year"],
            numDances: currentTotal["numDances"],
            numConcerts: currentTotal["numConcerts"],
            numCamps: currentTotal["numCamps"],
            numOther: currentTotal["numOther"],
            numGigs: currentTotal["numGigs"]
          };
          totalData.push(totalEntry);

          var overallTotalEntry = {
            year: "Total:",
            numDances: overallTotal["numDances"],
            numConcerts: overallTotal["numConcerts"],
            numCamps: overallTotal["numCamps"],
            numOther: overallTotal["numOther"],
            numGigs: overallTotal["numGigs"]
          };
          totalData.push(overallTotalEntry);

          gigData.shift(); // Remove first entry (header line)
          bandData = Array.from(bandSet);
          bandData.sort();
          this.bandList = bandData;
          this.gigList = gigData;
          this.gigTotals = totalData;
          this.isBusy = false;
          this.ready = "ready";
        });
    }
  },
  created() {
    this.getGigs();
  }
};
</script>

<style scoped lang="less">
.tables {
  opacity: 0;

  -webkit-transition: opacity 2.8s;
  -moz-transition: opacity 2.8s;
  transition: opacity 2.8s;

  &.ready {
    opacity: 1;
    overflow-x: scroll;
  }
}

.b-table {
  max-width: var(--max-width);
  margin: 0px auto;

  a {
    color: var(--primary-color);
    text-decoration: none;
  }
}

.smaller {
  font-size: 0.8rem;
  padding-bottom: 0.1rem;
}
</style>
